import React, { useEffect, useState } from "react"
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import { CookieBanner } from '@palmabit/react-cookie-law'

import Header from "./header"
import Footer from "./footer"
import Background from "./background"
import "../scss/header.scss"

const Layout = ({ path, children, location }) => {
  const [bgClass, setBgClass] = useState("homeBg")
  const [headerTheme, setHeaderTheme] = useState("lightHeader")

  const getCookie = (key) => {
    if (document) {
      var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)")
    }
    return b ? b.pop() : ""
  }

  useEffect(() => {
    setBgClass("homeBg")
    setHeaderTheme("lightHeader")
    if (path) {
      const splits = path.split("/")
      const route = splits[1] !== "products" ? splits[1] : splits[2]

      route ? setBgClass(route.toLowerCase() + "Bg") : setBgClass("homeBg")

      if (
        [
          "youschoolBg",
          "planktonBg",
          "privacy-policyBg",
          "terms-and-conditionsBg",
        ].includes(bgClass)
      ) {
        setHeaderTheme("darkHeader")
      }
    }
  })

  return (
    <>
      <Background bgClass={bgClass} />
      <div></div>
      <Header headerTheme={headerTheme} />

      <main role="main">
        <div id="page">{children}</div>

        <div id="footer">
          <Footer />
        </div>
      </main>
      { getCookie('rcl_consent_given') ? null : <div className="consentBanner"><CookieBanner
        message="We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services"
        wholeDomain={true}
        policyLink="/privacy-policy"
        showDeclineButton={true}
        declineButtonText="Decline"
        managePreferencesButtonText="Manage cookies"
        showPreferencesOption={false}
        onAccept={() => {
          if (getCookie('rcl_statistics_consent')) {
            console.log('Enabled TagManager and FB Pixel')
            initializeAndTrack(location)
          }
          if (getCookie('rcl_marketing_consent')) {
            window._paq =  window._paq || []
            console.log('Enabled Matomo')
            // remember tracking consent was given for all subsequent page views and visits
            window._paq.push(['rememberConsentGiven'])

            // OR remember cookie consent was given for all subsequent page views and visits
            window._paq.push(['rememberCookieConsentGiven'])
          }
        }}
        styles={{
          message: {
            color: 'white',
            fontSize: '14px',
            marginBottom: '10px'
          },
          policy: {
            color: 'white',
            fontSize: '14px'
          },
          button: {
            color: "white",
            borderRadius: "5px",
            border: 'none',
            marginLeft: "10px",
            paddingLeft: '10px',
            paddingRight: '10px',
            fontSize: '14px'
          }
        }}
      /></div> }
    </>
  )
}

export default Layout
