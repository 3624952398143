import React, { Component } from "react"
import { Link } from "gatsby"
import classnames from "classnames"
import $ from 'jquery'

import "../scss/header.scss"

import HomePageLogo from "../assets/img/compsol/palgroup_1.svg"
import MenuIcon from "../assets/img/youpalGroup/menu-icon.png"
import ChevronDown from "../assets/img/youpalGroup/chevron-down.png"

$(document).ready(function () {
  $(document).click(function (event) {
      var clickover = $(event.target);
      var _opened = $(".navbar-collapse").hasClass("show");
      if (_opened === true && !clickover.hasClass("navbar-toggler")) {
          $(".navbar-toggler").click();
      }
  });
});

class Header extends Component {
  constructor(props) {
    super(props)

    this.myRef = React.createRef()

    this.state = {
      isSticky: false,
      selectedNav: "",
      links: {
        companies: [
          "Youmedico",
          "Youdata",
          "Youradvisors",
          "Yougig",
          "Plankton",
          "Uteckie",
          "Youcloud",
        ],
        services: [
          'PMO',
          'Management and Consulting',
          'Subject matters experts',
          'Design'
        ],
        products: {
          // youMedico: ["Global", "RPM", "Telemedicine"],
          others: [
            "Yourvault",
            "Youchat",
            "YouCRM",
            "Youfolio",
            "Youdocs",
            "Youmauto",
            "Youmeet",
            "Youuni",
            "Youoptimize",
            "Yousuite",
            "Youpage",
          ],
        },
      },
    }

    this.expandNavSection = this._expandNavSection.bind(this)
    this.resetNavSection = this._resetNavSection.bind(this)

    window.addEventListener("scroll", () => {
      if (window.scrollY > 150) {
        this.setState({
          isSticky: true,
        })
      } else if (window.scrollY < 100) {
        this.setState({
          isSticky: false,
        })
      }
    })
  }

  _resetNavSection() {
    this.setState({
      selectedNav: "",
    })
  }

  _expandNavSection(event) {
    const navValue = event.target.querySelector("span")?.textContent

    this.setState({
      selectedNav: navValue,
    })
  }

  render() {
    const { isSticky, selectedNav, links } = this.state

    const showNav = (nav) =>
      selectedNav?.toLowerCase().includes(nav) ? "show" : ""

    const hClass = classnames(
      "navbarDiv",
      "sticky-top",
      isSticky ? "compactNav" : this.props.headerTheme
    )

    return (
      <div className={hClass}>
        <nav className="navbar navbar-expand-xl navbar-toggleable-lg">
          <Link className="navbar-brand" to="/">
            <HomePageLogo style={{ maxHeight: '60px', height: '200px' }} />
          </Link>
          <a
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarTop"
            aria-controls="navbarTop"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <img src={MenuIcon} />
          </a>

          <div className="collapse navbar-collapse" id="navbarTop">
            <ul className="mr-auto navbar-nav">
              <li
                className={
                  classnames(
                    'nav-item',
                    'dropdown',
                    showNav("companies")
                  )
                }
                onMouseEnter={this.expandNavSection}
                onMouseLeave={this.resetNavSection}
              >
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="dropdown01"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded={showNav("companies") === "show"}
                >
                  <span>Companies</span>
                  <img src={ChevronDown} />
                </a>
                <div
                  className={
                    classnames(
                      'ddAlignment',
                      'dropdown-menu',
                      'dropdown-menu-right',
                      showNav("companies")
                    )
                  }
                  aria-labelledby="dropdown01"
                  onMouseLeave={this.resetNavSection}
                >
                  {links.companies.map((company, index) => (
                    <Link
                      key={'mainlink' + index}
                      className="dropdown-item"
                      to={`/${company.toLowerCase()}`}
                      onClick={this.resetNavSection}
                    >
                      {company}
                    </Link>
                  ))}
                </div>
              </li>
              <li
                className={
                  classnames(
                    'nav-item',
                    'dropdown',
                    showNav("products")
                  )
                }
                onMouseEnter={this.expandNavSection}
                onMouseLeave={this.resetNavSection}
              >
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="dropdown02"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded={showNav("products") === "show"}
                >
                  <span>Products & Solutions</span>
                  <img src={ChevronDown} />
                </a>
                <div
                  className={
                    classnames(
                      'ddAlignment',
                      'dropdown-menu',
                      'dropdown-menu-right',
                      showNav("products")
                    )
                  }
                  onMouseLeave={this.resetNavSection}
                  aria-labelledby="dropdown02"
                >
                  { /* links.products.youMedico.map((product, index) => (
                    <Link
                      key={'mainlinkproducts' + index}
                      className="dropdown-item"
                      to="/products/youmedico"
                      onClick={this.resetNavSection}
                    >
                      {product}
                    </Link>
                  )) */ }
                  {links.products.others.map((product, index) => (
                    <Link
                      key={'mainlinkothers' + index}
                      className="dropdown-item"
                      to={`/products/${product.toLowerCase()}`}
                      onClick={this.resetNavSection}
                    >
                      {product}
                    </Link>
                  ))}
                </div>
              </li>
              { /*
              <li
                className={
                  classnames(
                    'nav-item',
                    'dropdown',
                    showNav("services")
                  )
                }
                onMouseEnter={this.expandNavSection}
                onMouseLeave={this.resetNavSection}
              >
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="dropdown03"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded={showNav("services") === "show"}
                >
                  <span>Services</span>
                  <img src={ChevronDown} />
                </a>
                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="dropdown03"
                  className={
                    classnames(
                      'ddAlignment',
                      'dropdown-menu',
                      'dropdown-menu-right',
                      showNav("services")
                    )
                  }
                >
                  {links.services.map((service, index) => (
                    <Link
                      key={'mainlink' + index}
                      className="dropdown-item"
                      to='/services/'
                      onClick={this.resetNavSection}
                    >
                      {service}
                    </Link>
                  ))}
                </div>
              </li>
              */ }
              <li className="nav-item">
                <a className="nav-link" href="https://uteckie.com">
                  News & Events
                </a>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about-us/">
                  About Us
                </Link>
              </li>
              <li
                className={
                  classnames(
                    'nav-item',
                    'dropdown',
                    showNav("more")
                  )
                }
                onMouseEnter={this.expandNavSection}
                onMouseLeave={this.resetNavSection}
              >
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="dropdown04"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded={showNav("more") === "show"}
                >
                  <span>More</span>
                  <img src={ChevronDown} />
                </a>
                <div
                  className={
                    classnames(
                      'ddAlignment',
                      'dropdown-menu',
                      'dropdown-menu-right',
                      showNav("more")
                    )
                  }
                  aria-labelledby="dropdown04"
                  onMouseLeave={this.resetNavSection}
                >
                  <Link className="dropdown-item" to="/services/">
                    Services
                  </Link>
                  <Link className="dropdown-item" to="/use-cases/">
                    Use cases
                  </Link>
                  <Link className="dropdown-item" to="/blog/">
                    Blog
                  </Link>
                  <Link className="dropdown-item" to="/contact-us/">
                    Contact us
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/follow-us/">
                  Follow Us
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    )
  }
}

export default Header
